jQuery(document).ready(function($) {
    /*
    |--------------------------------------------------------------------------
    | heightNormalize
    |--------------------------------------------------------------------------
    |
    | Нормализация высот динамических элементов
    |
    */
    // function heightNormalize(items) {
    //     setTimeout(function() {
    //         var mainDivs = $(items);
    //         var maxHeight = 0;
    //         for (var i = 0; i < mainDivs.length; ++i) {
    //             if (maxHeight < $(mainDivs[i]).height()) {
    //                 maxHeight = $(mainDivs[i]).height();
    //             }
    //         }
    //         for (var i = 0; i < mainDivs.length; ++i) {
    //             $(mainDivs[i]).css({
    //                 minHeight: maxHeight + 'px'
    //             });
    //         }
    //     }, 0);
    // }
    // setInterval(function() {
    //     heightNormalize('.catalog-2017 [data-height-normalize]');
    // }, 300);
});
