jQuery(document).ready(function($) {

    /*
    |--------------------------------------------------------------------------
    | FORM SUCCESS
    |--------------------------------------------------------------------------
    |
    | После успешной отправки формы отображаем благодарность, форму убираем
    |
    */

    $('.form-on-demand [data-form-btn]').click(function () {

        // время для получения статуса формы
        setInterval(function () {

            // если форма прошла валидацию то плавно скрываем контент
            if ($('.form-on-demand[data-form-success]').length) {
                $('.form-on-demand__text, .form-on-demand__fields-wrap').slideUp(300);

                // меняем контент на благодарствие
                setTimeout(function () {
                    $('.form-on-demand__thanks').slideDown(300);
                }, 300);
            }

        }, 300);
    });

});