jQuery(document).ready(function($) {
    var partial = $('.cart-products');

    // калькуляция корзины
    function calculateCart() {
        var items = partial.find('[data-item]');

        var itemsCosts = [];
        var total = 0;

        items.each(function(index) {
            var item = $(this);
            var minus = item.find('[data-minus]');
            var plus = item.find('[data-plus]');
            var cost = item.find('[data-cost]');
            var sum = item.find('[data-sum]');
            var input = item.find('input[type=number]');
            var count = input.val();
            var totalNodes = $(
                '.cart-products [data-total], .cart-form [data-total]'
            );

            cost.text(
                cost
                    .text()
                    .toString()
                    .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
            );

            function calculateSum(count) {
                sum.text(
                    (parseInt(
                        cost
                            .text()
                            .split(' ')
                            .join('')
                    ) * count)
                        .toString()
                        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
                );
                itemsCosts[index] = parseInt(
                    sum
                        .text()
                        .split(' ')
                        .join('')
                );
                total = itemsCosts.reduce((a, b) => a + b, 0);
                totalNodes.each(function() {
                    $(this).text(
                        total
                            .toString()
                            .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
                    );
                });
            }
            calculateSum(count);

            plus.on('click', function() {
                input.val(++count);
                calculateSum(count);
            });

            minus.on('click', function() {
                if (input.val() > 1) {
                    input.val(--count);
                    calculateSum(count);
                } else {
                    return;
                }
            });

            input.on('change', function() {
                count = $(this).val();
                calculateSum(count);
            });
        });
    }
    calculateCart();

    // удаление товара
    function itemRemove() {
        var items = partial.find('[data-item]');
        items.each(function(index) {
            var item = $(this);
            var removeButton = item.find('[data-remove]');

            removeButton.on('click', function() {
                item.remove();
                items = partial.find('[data-item]');
                calculateCart();
                if (!items.length) {
                    $('.cart-products__table').remove();
                    $('.cart__nav').remove();
                    $('.cart__form-wrap').remove();
                    $('.cart-products__empty').attr('hidden', false);
                }
            });
        });
    }
    itemRemove();

    // при нажатии на кнопку "оформить" открывается форма
    $('.cart-products__order').on('click', function() {
        $('.cart__nav-button[data-open]').trigger('click');
    });
});
