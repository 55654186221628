jQuery(document).ready(function ($) {

    // плавающий сайдбар
    // stickySidebar = function () {
    //     $('[data-sticky]').theiaStickySidebar({
    //         additionalMarginTop: 20
    //     });
    // };
    // if ($(document).width() >= 1120) {
    //     setTimeout(stickySidebar, 0);
    // };
    // setTimeout(stickySidebar, 0);
    // $(window).resize(function () {
    //     setTimeout(stickySidebar, 0);
    // });

});