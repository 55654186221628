jQuery(document).ready(function ($) {

    /*
    |--------------------------------------------------------------------------
    | TABTAB.JS
    |--------------------------------------------------------------------------
    |
    | Котфиг плагина табуляции
    |
    */

    /**
     * TABTAB.JS
     *
     * Вызов плагина табуляции
     *
     * @param {string} partial        Селектор компонента в котором находятся табы
     * @param {string} tabsNavigation Селектор списка с навигацией
     * @param {string} tabsWrapper    Селектор обертки табов
     */
    function initTabs(partial, tabsNavigation, tabsWrapper) {
        $(partial).tabtab({
            tabMenu: tabsNavigation, // direct container of the tab menu items
            tabContent: tabsWrapper, // direct container of the tab content items
            // next: '.tabs-controls__next', // next slide trigger
            // prev: '.tabs-controls__prev', // previous slide trigger

            startSlide: 1, // starting slide on pageload
            arrows: false, // keyboard arrow navigation
            //dynamicHeight: false, // if true the height will dynamic and animated.
            fixedHeight: true,
            useAnimations: true, // disables animations.

            easing: 'ease', // http://julian.com/research/velocity/#easing
            speed: 500, // animation speed
            slideDelay: 0, // delay the animation
            perspective: 1200, // set 3D perspective
            transformOrigin: 'center top', // set the center point of the 3d animation
            perspectiveOrigin: '50% 50%', // camera angle

            translateY: 0, // animate along the Y axis (val: px or ‘slide’)
            translateX: 16, // animate along the X axis (val: px or ‘slide’)
            scale: 1, // animate scale (val: 0-2)
            rotateX: 0, // animate rotation (val: 0deg-360deg)
            rotateY: 0, // animate Y acces rotation (val: 0deg-360deg)
            skewY: 0, // animate Y skew (val: 0deg-360deg)
            skewX: 0, // animate X skew (val: 0deg-360deg)
        });
    }

    // var heightFix = function () {
    //     $(".js-tabs-height").height($(".project-sample__item.active").outerHeight());
    // }
    // heightFix();
    // $(window).resize(function () {
    //     heightFix();
    // });

    // Компонент .project-sample--area--104
    initTabs(
        '.project-sample--area--104',
        '.project-sample__nav',
        '.project-sample__items-wrap'
    );
    initTabs(
        '.project-sample--area--104 .project-sample__item--1',
        '.project-sample__item-nav',
        '.project-sample__item-tabs-wrap'
    );
    initTabs(
        '.project-sample--area--104 .project-sample__item--2',
        '.project-sample__item-nav',
        '.project-sample__item-tabs-wrap'
    );

    // Компонент .project-sample--area--210
    initTabs(
        '.project-sample--area--210',
        '.project-sample__nav',
        '.project-sample__items-wrap'
    );
    initTabs(
        '.project-sample--area--210 .project-sample__item--1',
        '.project-sample__item-nav',
        '.project-sample__item-tabs-wrap'
    );
    initTabs(
        '.project-sample--area--210 .project-sample__item--2',
        '.project-sample__item-nav',
        '.project-sample__item-tabs-wrap'
    );
    initTabs(
        '.project-sample--area--210 .project-sample__item--3',
        '.project-sample__item-nav',
        '.project-sample__item-tabs-wrap'
    );

    // Компонент .project-sample--area--301
    initTabs(
        '.project-sample--area--301',
        '.project-sample__nav',
        '.project-sample__items-wrap'
    );
    initTabs(
        '.project-sample--area--301 .project-sample__item--1',
        '.project-sample__item-nav',
        '.project-sample__item-tabs-wrap'
    );
    initTabs(
        '.project-sample--area--301 .project-sample__item--2',
        '.project-sample__item-nav',
        '.project-sample__item-tabs-wrap'
    );
    initTabs(
        '.project-sample--area--301 .project-sample__item--3',
        '.project-sample__item-nav',
        '.project-sample__item-tabs-wrap'
    );


});