jQuery(document).ready(function($) {
    // прячем форму если пользователь уже оставлял свой телефон

    Cookies.get('showFormHelp') == 'false' ? $('.form-help').remove() : false;

    // показ формы при скроле
    $(window).scroll(function() {
        if ($(document).scrollTop() >= $(document).height() / 2) {
            $('.form-help').addClass('form-help--visible');
        } else {
            $('.form-help').removeClass('form-help--visible');
        }
    });

    // прячем форму при нажатии на кнопку закрытия
    $('.form-help__close').on('click', function() {
        $('.form-help').remove();
    });

    // проверка успешной отправки
    $('.form-help [data-form-btn]').click(function() {
        // время для получения статуса формы
        setInterval(function() {
            // если форма прошла валидацию то плавно скрываем контент
            if ($('.form-help[data-form-success]').length) {
                Cookies.set('showFormHelp', 'false');
                $('.form-help [data-info]').slideUp(0);

                // меняем контент на благодарствие
                setTimeout(function() {
                    $('.form-help [data-thanks]').slideDown(0);
                }, 0);
            }
        }, 0);
    });
});
