jQuery(document).ready(function ($) {

    /**
     * Выравнивание высот
     *
     * @param {string} node Элемент у которого нужно выровнять высоту
     */
    function normalizeHeight(node) {

        var maxColHeight = 0;

        $(node).height('auto');

        $(node).each(function () {
            if ($(this).height() > maxColHeight) {
                maxColHeight = $(this).height();
            }
        });

        $(node).height(maxColHeight);

    }

    setTimeout(function () {
        normalizeHeight('.process__item-title');
    }, 100);
    $(window).resize(function () {
        normalizeHeight('.process__item-title');
    });


});