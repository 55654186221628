jQuery(document).ready(function ($) {

    /*
    |--------------------------------------------------------------------------
    | SWIPER
    |--------------------------------------------------------------------------
    |
    | Слайдшоу
    |
    */

    var swiper = new Swiper('.swiper-container', {
        pagination: '.swiper-pagination',
        paginationClickable: true,
        effect: 'fade',
        speed: 1500,
        loop: true,
        autoplay: 5000,
        onlyExternal: true
    });

    /*
    |--------------------------------------------------------------------------
    | PARALLAX
    |--------------------------------------------------------------------------
    |
    | Параллакс-эффект секции
    |
    | !!! Для работы необходимо убрать свойства background-image и
    |     background-color
    |
    */

    $('.slideshow').parallax({
        imageSrc: $('.slideshow').attr('data-background'),
        bleed: 20
    });

});