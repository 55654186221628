jQuery(document).ready(function ($) {

    /*
    |--------------------------------------------------------------------------
    | ACCORDION
    |--------------------------------------------------------------------------
    |
    | Конфигурация аккордеона
    |
    */

    $('.series-description [data-accordion]').accordion({
        "transitionSpeed": 500
    });

});