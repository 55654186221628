jQuery(document).ready(function($) {
    $('.cart-form select').on('change', function() {
        if ($(this).val() == 'company') {
            $('.cart-form__hidden-wrap[data-company]').slideDown(0);
            $('.cart-form__hidden-wrap[data-single]').slideUp(0);
        } else {
            $('.cart-form__hidden-wrap[data-company]').slideUp(0);
            $('.cart-form__hidden-wrap[data-single]').slideDown(0);
        }
    });
});
