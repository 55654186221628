jQuery(document).ready(function($) {
    $('[data-form-phone-field]').mask('+7 (999) 999-99-99');
    $('[data-form-time-field]').mask('99:99');

    $('[data-form]').on('submit', function(event) {
        event.preventDefault();
        $(this)
            .find('[data-form-btn]')
            .trigger('click');
    });

    $('[data-form-btn]').click(function() {
        var $b = $(this);
        var $f = $(this).parents('[data-form]');
        var $s = $f.find('[data-form-status]');

        var action = $f.attr('action') || '/assets/forms/send-form.php';
        console.log(action);

        var bVal = $b.text();

        var status = [
            'Пожалуйста, укажите номер телефона',
            '',
            'При отправке сообщения возникли проблемы.'
        ];

        // тряска формы если не проходим валидацию при отправке
        $f.find('*[required]').each(function() {
            $(this).removeAttr('data-form-field-invalid');
            if (
                $(this).val() == '' ||
                $(this).val() == $(this).attr('placeholder')
            ) {
                $(this).attr('data-form-field-invalid', '');
                $f.find('[data-form-field-invalid]').addClass('shake animated');
                setTimeout(function() {
                    $f
                        .find('[data-form-field-invalid]')
                        .removeClass('shake animated');
                }, 1000);
            }
        });

        if ($f.find('[data-form-field-invalid]').length) {
            $s.html(status[0]).slideDown(500);
            return false;
        }

        $.ajax({
            url: action,
            type: 'POST',
            data: new FormData($f[0]),
            processData: false,
            contentType: false,
            success: function(response) {
                $f.attr('data-form-success', '');
            },
            error: function(jqXHR, textStatus, ex) {
                alert(textStatus + ',' + ex + ',' + jqXHR.responseText);
            }
        });
        return false;
    });
});
