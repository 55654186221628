jQuery(document).ready(function($) {
    // offsetRelative (or, if you prefer, positionRelative)
    (function($) {
        $.fn.offsetRelative = function(top) {
            var $this = $(this);
            var $parent = $this.offsetParent();
            var offset = $this.position();
            if (!top) return offset;
            else if ($parent.get(0).tagName == 'BODY')
                // Didn't pass a 'top' element
                return offset;
            else if ($(top, $parent).length)
                // Reached top of document
                return offset;
            else if ($parent[0] == $(top)[0])
                // Parent element contains the 'top' element we want the offset to be relative to
                return offset;
            else {
                // Reached the 'top' element we want the offset to be relative to
                // Get parent's relative offset
                var parent_offset = $parent.offsetRelative(top);
                offset.top += parent_offset.top;
                offset.left += parent_offset.left;
                return offset;
            }
        };
        $.fn.positionRelative = function(top) {
            return $(this).offsetRelative(top);
        };
    })(jQuery);

    // Конфигурация слайдера мин. и макс. значений
    $('.filter [data-range]').nstSlider({
        rounding: {
            '1': '100000000'
        },
        crossable_handles: false,
        left_grip_selector: '[data-range-left-grip]',
        right_grip_selector: '[data-range-right-grip]',
        value_bar_selector: '[data-range-bar]',
        value_changed_callback: function(cause, leftValue, rightValue) {
            var $container = $(this).parent();
            $container.find('[data-range-left-value]').val(leftValue);
            $container.find('[data-range-right-value]').val(rightValue);
        }
    });
    $(
        '.filter [data-range-left-value], .filter [data-range-right-value]'
    ).keyup(function() {
        setTimeout(function() {
            $('.filter [data-range]').nstSlider(
                'set_position',
                parseInt($('[data-range-left-value]').val()),
                parseInt($('[data-range-right-value]').val())
            );
        }, 1000);
    });

    // сброс фильтра
    (function() {
        var filter = $('.filter');
        var cancel = filter.find('[data-cancel]');
        cancel.on('click', function() {
            location.reload();
        });
    })();

    // всплывающий тултип
    (function() {
        var fields = $('.filter__fieldset input');
        fields.each(function() {
            $(this).on('change', function() {
                $('.filter__tooltip').remove();

                var formData = new FormData($('.filter')[0]);
                var field = $(this);
                var response = {};
                formData.append('ajax', 'y');

                $.ajax({
                    url: location.href,
                    // url: 'http://centrkotlov.dev.krown.cc/catalog/gazovye/',
                    type: 'post',
                    cache: false,
                    contentType: false,
                    processData: false,
                    data: formData,
                    success: function(data) {
                        response = JSON.parse(data);
                        setTimeout(function() {
                            var tooltipText = response.PRODUCT_COUNT
                                ? response.PRODUCT_COUNT + ' ' + response.WORD
                                : 'нет товаров';
                            var offset =
                                field.offsetRelative('.filter').top - 8;

                            field
                                .closest('.filter')
                                .prepend(
                                    $(
                                        '<span class="filter__tooltip" style="top:' +
                                            offset +
                                            'px;">' +
                                            tooltipText +
                                            ' <a href="#" class="filter__tooltip-link" onclick="$(\'.filter__send\').trigger(\'click\');$(\'.filter__tooltip\').remove();">Показать</a></span>'
                                    )
                                );
                        }, 300);
                    },
                    error: function(data) {
                        console.log(data);
                    }
                });
            });
        });
    })();
});
