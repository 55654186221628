jQuery(document).ready(function($) {
    /*
    |--------------------------------------------------------------------------
    | MAGAMENU
    |--------------------------------------------------------------------------
    |
    | Скриптуем главное меню
    |
    */

    // инициализация плагина табуляции
    $('.main-menu').tabtab({
        tabMenu: '.main-menu__nav', // direct container of the tab menu items
        tabContent: '.main-menu__submenu', // direct container of the tab content items
        // next: '.tabs-controls__next', // next slide trigger
        // prev: '.tabs-controls__prev', // previous slide trigger

        startSlide: 1, // starting slide on pageload
        arrows: false, // keyboard arrow navigation
        dynamicHeight: true, // if true the height will dynamic and animated.
        fixedHeight: false,
        useAnimations: true, // disables animations.

        easing: 'ease', // http://julian.com/research/velocity/#easing
        speed: 100, // animation speed
        slideDelay: 0, // delay the animation
        perspective: 1200, // set 3D perspective
        transformOrigin: 'center top', // set the center point of the 3d animation
        perspectiveOrigin: '50% 50%', // camera angle

        translateY: 0, // animate along the Y axis (val: px or ‘slide’)
        translateX: 0, // animate along the X axis (val: px or ‘slide’)
        scale: 1, // animate scale (val: 0-2)
        rotateX: 0, // animate rotation (val: 0deg-360deg)
        rotateY: 0, // animate Y acces rotation (val: 0deg-360deg)
        skewY: 0, // animate Y skew (val: 0deg-360deg)
        skewX: 0 // animate X skew (val: 0deg-360deg)
    });

    // убирает подсветку активного таба т.к. он может несовпадать с текущим пунктом меню
    $('.main-menu__nav-item').removeClass('active');

    // возвращаем переход по ссылке, т.к. его отключил плагин табуляции
    $('.main-menu__nav-trigger').on('click', function(e) {
        window.location = $(this).attr('href');
    });

    // делаем смену табов при наведении
    $('.main-menu__nav-item').on('mouseover', function() {
        $(this).trigger('click');
    });

    // убирает подсветку активного таба после потери фокуса меню
    $('.main-menu').on('mouseleave', function() {
        setTimeout(function() {
            $('.main-menu__nav-item').removeClass('active');
        }, 300);
    });

    // костыль для исправления подсчета высоты таба
    $('.main-menu__nav-item')
        .trigger('mouseover')
        .trigger('mouseleave');
});
