jQuery(document).ready(function ($) {

    /*
    |--------------------------------------------------------------------------
    | FORM
    |--------------------------------------------------------------------------
    |
    | Управление формой
    |
    */

    // показ/скрытие формы
    function showForm() {

        var open = $('.cart__nav-button[data-open]');
        var close = $('.cart__nav-button[data-close]');

        open.on('click', function () {
            $(this).addClass('cart__nav-button--state--active');
            close.removeClass('cart__nav-button--state--active');
            $('.cart__form-wrap').slideDown(500);
        });

        close.on('click', function () {
            $(this).addClass('cart__nav-button--state--active');
            open.removeClass('cart__nav-button--state--active');
            $('.cart__form-wrap').slideUp(500);
        });

    }

    showForm();

});